import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import ButtonWithSpinner from "../hub/HelperComponents/ButtonWithSpinner";
import ModalWrapper from "./Helpers/ModalWrapper";
import {
  isChrome,
  isFirefoxBrowser,
  isMicrosoftEdgeBrowser,
  isSafari,
} from "../../utils/extensionHelpers";
import {
  CHROME_MICROPHONE_PERMISSIONS_URL,
  FIREFOX_MICROPHONE_PERMISSIONS_URL,
  MICROSOFT_EDGE_MICROPHONE_PERMISSIONS_URL,
  SAFARI_MICROPHONE_PERMISSIONS_URL,
  browserMicrophonePermissionsGuide,
} from "../../utils/browserConstants";
import useMainStore from "../../utils/hooks/ReduxHooks/mainStore";

const UnsupportedMicrophonePermissionsModal = () => {
  // REDUX STore
  const { main, setUnsupportedMicrophonePermissionModalData } = useMainStore();
  const { unsupportedMicrophoneModalData } = main;

  const [browserError, setBrowserError] = useState(
    unsupportedMicrophoneModalData?.browserError || ""
  );

  const closeModal = useCallback(() => {
    setUnsupportedMicrophonePermissionModalData({ show: false });
  }, [setUnsupportedMicrophonePermissionModalData]);

  const requestMicrophoneAccess = useCallback(
    async (withoutSettingError) => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        setBrowserError(false);
        closeModal();
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        if (!withoutSettingError) {
          setBrowserError(true);
        }
      }
    },
    [closeModal]
  );

  useEffect(() => {
    requestMicrophoneAccess(true);
  }, [requestMicrophoneAccess]);

  const renderBrowserError = useMemo(() => {
    if (isChrome()) {
      return (
        <div>
          <ul>
            {browserMicrophonePermissionsGuide.chrome.map((step, index) => (
              <li key={index}>{step}</li>
            ))}
          </ul>
          <span>
            <b className="guide-url-text">Guide URL:</b>{" "}
            <a target="__blank" href={CHROME_MICROPHONE_PERMISSIONS_URL}>
              Chrome Microphone Settings
            </a>
          </span>
        </div>
      );
    }

    if (isMicrosoftEdgeBrowser()) {
      return (
        <div>
          <ul>
            {browserMicrophonePermissionsGuide.edge.map((step, index) => (
              <li key={index}>{step}</li>
            ))}
          </ul>
          <span>
            <b className="guide-url-text">Guide URL:</b>{" "}
            <a
              target="__blank"
              href={MICROSOFT_EDGE_MICROPHONE_PERMISSIONS_URL}
            >
              Edge Microphone Settings
            </a>
          </span>
        </div>
      );
    }

    if (isSafari()) {
      return (
        <div>
          <ul>
            {browserMicrophonePermissionsGuide.safari.map((step, index) => (
              <li key={index}>{step}</li>
            ))}
          </ul>
          <span>
            <b className="guide-url-text">Guide URL:</b>{" "}
            <a target="__blank" href={SAFARI_MICROPHONE_PERMISSIONS_URL}>
              Safari Microphone Settings
            </a>
          </span>
        </div>
      );
    }

    if (isFirefoxBrowser()) {
      return (
        <div>
          <ul>
            {browserMicrophonePermissionsGuide.firefox.map((step, index) => (
              <li key={index}>{step}</li>
            ))}
          </ul>
          <span>
            <b className="guide-url-text">Guide URL:</b>{" "}
            <a target="__blank" href={FIREFOX_MICROPHONE_PERMISSIONS_URL}>
              Firefox Microphone Settings
            </a>
          </span>
        </div>
      );
    }

    return null;
  }, []);

  return (
    <ModalWrapper
      id="unsupportedMicrophoneModal"
      show={unsupportedMicrophoneModalData?.show}
      shakeModal={closeModal}
      closeModal={closeModal}
      className={"locked-info-modal"}
    >
      <div>
        <div className="mb-3">
          <h3 className="mb-3">Microphone access unavailable</h3>
          {browserError ? (
            <div className="mb-2">
              <p>
                Browser doesn't allow us to access the microphone. You need to
                enable it manually, or consider using a different browser.{" "}
              </p>
              <h4 className="mt-3 guide-url-text">
                Here is the guide to enable microphone permissions:
              </h4>
              <div>{renderBrowserError}</div>
            </div>
          ) : (
            <p className="mb-2">
              {unsupportedMicrophoneModalData?.customMicrohphoneMessage ||
                ReactHtmlParser(`Access to the microphone is not available via this browser. <br />
                Please enable microphone access in your browser, or consider using
                a different browser.`)}
            </p>
          )}
          {!browserError && (
            <div className="d-flex align-items-center justify-content-end mt-4">
              <ButtonWithSpinner onClick={() => requestMicrophoneAccess(false)}>
                Request Microphone Access
              </ButtonWithSpinner>
            </div>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default UnsupportedMicrophonePermissionsModal;
