import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { ReactComponent as DialpadSvg } from "../../../assets/img/icons-new/keypad/keypad-black.svg";

const KeypadCircleButton = (props) => {
  const { disabled, onClick, className } = props;
  return (
    <span className="flexer-col">
      <span
        className={clsx("calling-features-wrapper", className, {
          disabled: disabled,
        })}
        onClick={onClick}
      >
        <DialpadSvg height={28} width={28} />
      </span>
      <label className="mt-1">Keypad</label>
    </span>
  );
};

KeypadCircleButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

KeypadCircleButton.defaultProps = {
  disabled: false,
  onClick: () => {},
  className: "",
};

export default KeypadCircleButton;
