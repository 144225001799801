import React from "react";
import { connect } from "react-redux";
import { Drawer } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { history } from "../../store";
import { ModalsContext } from "../../ModalsContext";
import { fetchContactData } from "../../actions/contacts";
import { checkAccount, fetchVoicePrice } from "../../actions/calls";
import { ReactComponent as ChevronDown } from "../../assets/img/icons-new/Chevron/chevron-down/chevron-down-black.svg";
import { ReactComponent as CloseSvg } from "../../assets/img/icons-new/general/close/close-gray.svg";
import { ReactComponent as MinimizeSvg } from "../../assets/img/icons-new/general/minimize/minimize-black.svg";
import { ReactComponent as CallIcon } from "../../assets/img/icons-new/voice-tab/all-voice-message/all-voice-message-white.svg";
import Avatar from "../Avatar";
import { contactsName, formatDuration, formatPhoneNumber } from "../../helpers";
import SpanWithTooltip from "../SpanWithTooltip";
import TwilioConfig from "../../TwilioConfig";
import LongNamesModal from "./LongNamesModal";
import Spinner from "../hub/HelperComponents/Spinner";
import {
  setAutoCallingFromNumber,
  setOneToOneCallingId,
} from "../../actions/threads";
import VoiceDialpad from "../hub/Voice/VoiceDialpad";
import SearchBox from "../hub/HelperComponents/SearchBox";
import { isNotAnEmptyArray } from "../../utils/settingsHelpers";
import withResponsive from "../../withResponsive";
import { isMdOrBelowBreakpoint } from "../../utils/breakpoints";
import TooltipWhenOverflow from "../hub/HelperComponents/TooltipWhenOverflow";
import MessageCircleButton from "../hub/HelperComponents/MessageCircleButton";
import KeypadCircleButton from "../hub/HelperComponents/KeypadCircleButton";

function mapStateToProps(store, ownProps) {
  const contactId = parseInt(ownProps.param);
  let contactData = null;
  let contactStatus = null;

  if (contactId) {
    let contact =
      typeof store.contacts.data[contactId] === "undefined"
        ? null
        : store.contacts.data[contactId];
    if (contact) {
      contactData = [];
      if (contact.contacts_ids) {
        for (let i = 0; i < contact.contacts_ids.length; i++) {
          if (
            typeof store.contacts.data[contact.contacts_ids[i]] !== "undefined"
          ) {
            contactData.push(store.contacts.data[contact.contacts_ids[i]]);
          }
        }
      }
    }
    contactStatus =
      typeof store.contacts.dataStatus[contactId] === "undefined"
        ? null
        : store.contacts.dataStatus[contactId];
  }

  let voicePrice = null;
  let voicePriceStatus = null;
  if (contactData && contactData.length) {
    const countryId = contactData[0].country_id;
    voicePrice =
      typeof store.calls.voicePriceByCountry[countryId] !== "undefined"
        ? store.calls.voicePriceByCountry[countryId]
        : null;
    voicePriceStatus =
      typeof store.calls.voicePriceByCountryStatus[countryId] !== "undefined"
        ? store.calls.voicePriceByCountryStatus[countryId]
        : null;
  }

  let callStatus = null;
  let callFrom = null;
  let callTime = null;
  let callStartedAt = null;
  let callMinutePrice = null;
  if (store.twilio.callContactId === contactId) {
    callStatus = store.twilio.callStatus;
    callFrom = store.twilio.callFrom;
    callTime = store.twilio.callTime;
    callStartedAt = store.twilio.callStartedAt;
    callMinutePrice = store.twilio.callMinutePrice;
  }

  return {
    companyId: store.companies.currentCompany.id,
    contactId: contactId,
    contactData: contactData,
    contactStatus: contactStatus,
    checkAccountResult: store.calls.checkAccount,
    checkAccountStatus: store.calls.checkAccountStatus,
    voicePrice: voicePrice,
    voicePriceStatus: voicePriceStatus,
    twilioReady: store.twilio.ready,
    callStatus: callStatus,
    callFrom: callFrom,
    callTime: callTime,
    callStartedAt: callStartedAt,
    callMinutePrice: callMinutePrice,
    autoCallingFromNumber: store.threads.autoCallingFromNumber,
    twilioCallContactId: store.twilio.callContactId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchContactData: (companyId, contactId) =>
      dispatch(fetchContactData(companyId, contactId)),
    checkAccount: (companyId, contactId) =>
      dispatch(checkAccount(companyId, contactId)),
    fetchVoicePrice: (companyId, countryId) =>
      dispatch(fetchVoicePrice(companyId, countryId)),
    setOneToOneCallingId: (callingId) =>
      dispatch(setOneToOneCallingId(callingId)),
    setAutoCallingFromNumber: (callingNumber) =>
      dispatch(setAutoCallingFromNumber(callingNumber)),
  };
}

class NewCallContactDrawer extends React.Component {
  static contextType = ModalsContext;

  constructor(props) {
    super(props);
    this.timeInterval = null;
    this.refreshContactTimeout = null;
    this.fromRef = React.createRef();
    this.updateView = this.updateView.bind(this);
    this.updateData = this.updateData.bind(this);
    this.close = this.close.bind(this);
    this.decline = this.decline.bind(this);
    this.addTagsToUrl = this.addTagsToUrl.bind(this);

    this.state = {
      isShown: false,
      isHide: false,
      time: 0,
      spent: 0,
      longNameModalContent: "",
      showLongNameModal: false,
      isClosing: false,
      showKeypad: false,
      queryState: "",
      isMinimized: false,
    };
  }

  componentDidMount() {
    this.updateView();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      autoCallingFromNumber,
      voicePriceStatus,
      contactId,
      contactData,
      voicePrice,
      setAutoCallingFromNumber,
      callStatus,
      twilioCallContactId,
    } = this.props;
    const currentOpen = this.state.isShown && !this.state.isHide;
    const prevOpen = prevState.isShown && !prevState.isHide;
    if (
      prevProps.show !== this.props.show ||
      this.state.isShown !== prevState.isShown ||
      this.state.isHide !== prevState.isHide
    ) {
      this.updateView();
    }

    if (
      currentOpen !== prevOpen &&
      currentOpen &&
      contactId &&
      !twilioCallContactId
    ) {
      this.props.checkAccount(this.props.companyId, contactId);
    }

    if (
      !this.state.isClosing &&
      currentOpen &&
      this.props.contactStatus == null &&
      contactId
    ) {
      this.props.fetchContactData(this.props.companyId, contactId);
    }

    if (
      currentOpen &&
      (this.props.contactStatus === "success" ||
        isNotAnEmptyArray(contactData)) &&
      this.props.voicePriceStatus == null
    ) {
      this.props.fetchVoicePrice(
        this.props.companyId,
        contactData[0].country_id
      );
    }
    if (
      (callStatus === "connected" && prevProps.callStatus !== "connected") ||
      (callStatus === "connected" && currentOpen !== prevOpen)
    ) {
      this.timeInterval = setInterval(this.updateData, 1000);
    }

    // Refresh contact data after failed call (to check number is unformatted)
    if (
      callStatus !== "connected" &&
      prevProps.callStatus === "connected" &&
      this.state.time < 5 &&
      contactId
    ) {
      clearTimeout(this.refreshContactTimeout);
      this.refreshContactTimeout = setTimeout(() => {
        this.props.fetchContactData(this.props.companyId, contactId);
      }, 2000);
    }

    // Auto dial call after fetching voice price
    if (voicePriceStatus === "success" && autoCallingFromNumber) {
      const unformattedNumber =
        contactData &&
        contactData.length > 0 &&
        contactData[0].number_lookup &&
        !contactData[0].number_lookup.type;
      setAutoCallingFromNumber(null);
      if (!unformattedNumber) {
        TwilioConfig.callContact(
          autoCallingFromNumber,
          contactId,
          contactData[0].phone_number,
          voicePrice,
          true // Without setting one to one calling id
        );
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeInterval);
  }

  updateView() {
    this.setState({ isClosing: false });

    if (this.props.show && !this.state.isShown) {
      setTimeout(() => {
        if (!this) {
          return;
        }
        this.setState({ isShown: true, isHide: false });
      }, 0);
    }
    if (!this.props.show && this.state.isShown) {
      if (!this) {
        return;
      }
      this.setState({ isHide: true });
      setTimeout(() => {
        this.setState({ isShown: false });
      }, 200);
    }
  }

  updateData() {
    const time = this.props.callStartedAt
      ? Math.ceil((new Date().getTime() - this.props.callStartedAt) / 1000)
      : 0;
    const spent = this.props.callStartedAt
      ? Math.ceil(time / 60) * this.props.callMinutePrice
      : 0;
    this.setState({
      time: time,
      spent: spent,
    });
  }

  minimizeSideDrawer = () => {
    this.setState({ isMinimized: true });
    setTimeout(() => {
      this.props.setOneToOneCallingId(null);
      this.setState({ isMinimized: false }); // Optionally close the drawer after animation
    }, 300); // Match this with the animation duration
  };

  close(e) {
    e.preventDefault();
    this.setState({ isClosing: true }, this.addTagsToUrl);
  }

  addTagsToUrl() {
    const urlHash = this.props.location.hash || "";
    const pathname = this.props.location.pathname;

    if (urlHash.endsWith("/?new")) {
      // It the request to open a modal for new user then on close, remove the contact ID from url path
      let newPathnameArray = pathname.split("/");
      newPathnameArray.pop();
      const newPathname = newPathnameArray.join("/");

      // in case of new user Add a ?refresh tag to url for HubVoiceList componentDidUpdate to
      // know to fetch update voiceThreadList
      history.push(`${newPathname}/?refresh`);
    } else {
      // in case of Existing user Add a ?refreshExisting tag to url for HubVoiceList componentDidUpdate to
      // know to fetch update voiceThreadList but keep the existing user selected
      history.push(`${pathname}?refreshExisting`);
    }
    this.props.setOneToOneCallingId(null);
  }

  decline(e) {
    e && e.preventDefault();
    e && e.stopPropagation();
    TwilioConfig.disconnect();
  }

  toggleLongNameModal = () => {
    this.setState({ showLongNameModal: !this.state.showLongNameModal });
  };

  handleLongName = (threadContactName) => {
    if (window.innerWidth < 600 && threadContactName?.length >= 16) {
      this.setState({ longNameModalContent: threadContactName });
      this.toggleLongNameModal();
    }
  };

  onClickedKeypadBtn = () => {
    const { callStatus } = this.props;
    if (callStatus !== "connected") return null;
    this.setState({ showKeypad: true });
  };

  closeDialpad = () => {
    this.setState({ showKeypad: false });
  };

  onClickDigit = (digit) => {
    const { queryState } = this.state;
    const newQuery = queryState + digit;
    // Send Digits to twilio config
    TwilioConfig.sendDigits(newQuery);
    this.setState({ queryState: newQuery });
  };

  onClickBackSpace = () => {
    const { queryState } = this.state;
    const newQuery = queryState.slice(0, queryState.length - 1);
    this.setState({ queryState: newQuery });
  };

  handleQueryChange = (event) => {
    this.setState({ queryState: event.target.value });
  };

  clearQuery = (event) => {
    this.setState({ queryState: "" });
  };

  onClickMessageBtn = () => {
    const { history, breakpoint, setOneToOneCallingId, contactId } = this.props;
    history.push(`/hub/messages/contact/${contactId}`);
    if (isMdOrBelowBreakpoint(breakpoint)) {
      setOneToOneCallingId(null);
    }
  };

  render() {
    const { contactData, location, callStatus } = this.props;

    const {
      showLongNameModal,
      longNameModalContent,
      showKeypad,
      queryState,
      isShown,
      isMinimized,
    } = this.state;

    if (!isShown) return null;

    const unformattedNumber =
      contactData &&
      contactData.length > 0 &&
      contactData[0].number_lookup &&
      !contactData[0].number_lookup.type;

    let content = (
      <p className={"text-center text-muted"}>
        <Spinner />
      </p>
    );
    if (
      this.props.contactData &&
      this.props.contactData.length &&
      this.props.checkAccountStatus === "success"
    ) {
      if (this.props.checkAccountResult) {
        const name = contactsName(this.props.contactData, false, 4);
        const phonecode = this.props.contactData[0].country
          ? "" + this.props.contactData[0].country.phonecode
          : "";
        const number = formatPhoneNumber(
          "" + phonecode + this.props.contactData[0].phone_number
        );
        content = (
          <React.Fragment>
            {callStatus === "connecting" && (
              <h5 className="modal-title" id="MakeCallToUserLabel">
                Calling
              </h5>
            )}

            {this.props.contactData && this.props.contactData.length === 1 && (
              <Avatar
                isGroup={false}
                firstName={this.props.contactData[0].first_name}
                lastName={this.props.contactData[0].last_name}
                email={this.props.contactData[0].email}
                bgColor={this.props.contactData[0].color}
                size={75}
              />
            )}
            {this.props.contactData && this.props.contactData.length > 1 && (
              <Avatar isMultipleContacts size={75} />
            )}
            <div className="contact-info p-0">
              <TooltipWhenOverflow maxWidth={270} text={name}>
                <span onClick={() => this.handleLongName(name)}>{name}</span>
              </TooltipWhenOverflow>
              <span className="number">{number}</span>
              {!unformattedNumber && (
                <span className="call-timer">
                  {formatDuration(this.state.time)}
                </span>
              )}
            </div>

            {!unformattedNumber && !showKeypad && (
              <>
                <div className="credit-info">
                  {(this.props.voicePriceStatus !== "success" ||
                    !this.props.twilioReady) && <span>Initializing...</span>}

                  {this.props.voicePriceStatus === "success" &&
                    this.props.twilioReady && (
                      <div className="flexer-col">
                        {callStatus === "connected" && (
                          <span
                            className="mr-3 d-block"
                            style={{ fontWeight: 400 }}
                          >
                            You spent {this.state.spent} credits
                          </span>
                        )}
                        {callStatus !== "connected" && (
                          <span className="mr-3">Ready</span>
                        )}
                        <span>{this.props.voicePrice} credits/min</span>
                      </div>
                    )}
                </div>
                <div className="w-100 d-flex align-items-center justify-content-around mt-4">
                  <MessageCircleButton
                    active={location?.pathname?.includes("/hub/messages")}
                    onClick={this.onClickMessageBtn}
                  />
                  <KeypadCircleButton
                    disabled={callStatus !== "connected"}
                    onClick={this.onClickedKeypadBtn}
                  />
                </div>
                <a href="#void" className="decline" onClick={this.decline}>
                  <CallIcon />
                </a>
              </>
            )}

            {unformattedNumber && (
              <div className="mt-5 text-danger">
                <SpanWithTooltip
                  placement="top"
                  title={
                    <span>
                      Number lookup completed.
                      <br />
                      Format Error.
                    </span>
                  }
                  id="CallContactModalUnformattedMsg"
                >
                  <i className="fa fa-warning mr-1"></i>
                  Unformatted number
                </SpanWithTooltip>
              </div>
            )}

            {showKeypad && (
              <div>
                <div className="search-wrapper-outer d-flex border-top pt-1 pb-1">
                  <SearchBox
                    placeholder={""}
                    value={queryState}
                    onChange={this.handleQueryChange}
                    onClickClear={this.clearQuery}
                    className={"voice-dialer-input-box w-100"}
                    autoFocus={true}
                  />
                  <ChevronDown
                    onClick={this.closeDialpad}
                    height={35}
                    width={35}
                    className="cursor-pointer dialpad-hidden-icon"
                  />
                </div>
                <VoiceDialpad
                  onlyDisplayDeclineBtn={true}
                  onClickDeclineBtn={this.decline}
                  withBorderTop={false}
                  showBackSpaceBtn={queryState.trim()}
                  onClickDigit={this.onClickDigit}
                  onClickBackSpace={this.onClickBackSpace}
                />
              </div>
            )}
          </React.Fragment>
        );
      } else {
        content = (
          <p className={"text-center text-danger"}>
            Your account was marked for review, please contact support for any
            questions.
          </p>
        );
      }
    }

    return (
      <>
        <Drawer
          classes={{
            root: `voice-dialer-main-container making-call`,
            paper: `voice-dialer-paper ${
              isMinimized ? "minimized-effect" : "maximized-effect"
            }`,
          }}
          variant="permanent"
          anchor="right"
          open={isShown}
          onClose={this.close}
          hideBackdrop={true}
        >
          <div className="themed-modal h-100">
            <div className="modal-header">
              {(callStatus === "connecting" || callStatus === "connected") && (
                <div
                  className="close-icon cursor-pointer"
                  data-dismiss="modal"
                  data-tooltip="tooltip"
                  data-placement="top"
                  title="Minimize"
                  onClick={this.minimizeSideDrawer}
                >
                  <MinimizeSvg width={35} height={35} />
                </div>
              )}
              {callStatus !== "connecting" && callStatus !== "connected" && (
                <div className="close-icon" onClick={this.close}>
                  <CloseSvg />
                </div>
              )}
            </div>
            <div className="modal-body p-0 flexer-col h-100">{content}</div>
          </div>
        </Drawer>
        {showLongNameModal && (
          <LongNamesModal
            show={showLongNameModal}
            onClose={this.toggleLongNameModal}
            modalBodyContent={longNameModalContent}
          />
        )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withResponsive(withRouter(NewCallContactDrawer)));
